import React, { useEffect, useState } from "react";
import { IconButton, Grid, Slider } from "@material-ui/core/";
import { VolumeUp } from "@material-ui/icons/";

const VolumeController = ({ player, setPlayerState }) => {

  const [volume, setVolume] = useState(50);

  const volumeChange = (e, newVal) => {
    setVolume(newVal);
    player.volume = newVal / 100;
    localStorage.setItem("volume", newVal )
  };

  useEffect(() => {

    const locVolume = localStorage.getItem("volume")
    if ( locVolume ) {
      try {
        const val = parseInt(locVolume)
        setVolume( val  )
        player.volume = val / 100;
      } catch (error) { }
    }

  }, [])


  return (

      <Grid container spacing={1} style={{ maxWidth: "300px" }}>
        <Grid item>
          <VolumeUp color="primary" />
        </Grid>
        <Grid item xs={3}>
          <Slider value={volume} onChange={volumeChange} />
        </Grid>
        <Grid item xs={2}>
          <div style={{ fontSize: 13, color:'#404040', marginTop: 5, fontFamily:'Roboto', marginLeft: 5 }}> { volume } </div>
        </Grid>
      </Grid>
  );
};

export default VolumeController;
